<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-list-item>
    <v-list-item-avatar size="54">
      <font-awesome-icon :icon="['fad', 'box-open']" size="2x" class="grey--text text--lighten-1" />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title class="o-80">
        {{ title }}
      </v-list-item-title>
      <v-list-item-subtitle class="o-60">
        {{ subtitle }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  export default {
    name: 'EmptyList',
    props: {
      title: String,
      subtitle: String
    }
  }
</script>
